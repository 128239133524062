import React from "react"
import Img from "gatsby-image/withIEPolyfill"

export const Image = ({
  image,
  imageAlt,
  objectFit = "cover",
  objectPosition = "50% 50%",
}) => {
  if (!image) {
    return <></>
  } else if (image.childImageSharp && image.childImageSharp.fluid) {
    return (
      <Img
        fluid={image.childImageSharp.fluid}
        objectFit={objectFit}
        objectPosition={objectPosition}
        alt={imageAlt}
        style={{ height: "100%" }}
      />
    )
  } else if (image.childImageSharp && image.childImageSharp.fixed) {
    return <Img fixed={image.childImageSharp.fixed} alt={imageAlt} />
  } else if (image.publicURL && image.publicURL.endsWith(".svg")) {
    return (
      <object data={image.publicURL} type="image/svg+xml">
        <p>SVG image</p>
      </object>
    )
  } else if (image.publicURL) {
    return <img src={image.publicURL} alt={imageAlt} />
  } else if (image.url) {
    return (
      <img src={`${process.env.GATSBY_MEDIA_BASE_URL}${image.url}`} alt={imageAlt} />
    )
  } else {
    return <></>
  }
}
