import "./category-selection.scss"
import React, { useContext, useEffect, useState } from "react"
import { ProjectContext } from "../../pages"
import { navigate } from "gatsby"

interface Props {
  categories: [
    {
      id: string
      name: string
      slug: string
    }
  ]
}

export const CategorySelection: React.FC<Props> = ({ categories }) => {
  const { currentCategory, setCurrentCategory } = useContext(ProjectContext)
  const [categoryButtons, setCategoryButtons] = useState(null)

  useEffect(() => {
    let buttons = []
    let cats = [...categories]

    cats.push({ id: "50000", name: "All", slug: "all" }) &&
      cats
        .filter(x => x.slug !== "front-page")
        .sort((a, b) => {
          if (a.slug > b.slug) {
            return 1
          } else if (a.slug < b.slug) {
            return -1
          }
          return 0
        })
        .map(category => {
          buttons.push(
            <button
              key={category.id}
              className={`${
                currentCategory === category.slug ? "selected" : ""
              }`}
              onClick={() => {
                setCurrentCategory(category.slug)
                navigate(`/?category=${category.slug}`)
              }}
            >
              {category.name}
            </button>
          )
        })

    setCategoryButtons(buttons)
  }, [currentCategory, categories])

  return <div className="categories">{categoryButtons}</div>
}
