import React, { useState } from "react"
import { Video } from "../video/video"
import { Image } from "../image/image"

interface Props {
  mime: string
  mediaItem: {
    url: string
    alternativeText: string
  }
  videoSettings?: {
    autoPlay?: boolean
    showControls?: boolean
    muted?: boolean
    loop?: boolean
  }
}

export const Media: React.FC<Props> = ({ mime, mediaItem, videoSettings }) => {
  const getMedia = item => {
    if (
      mime.includes("video") ||
      (item.publicURL && item.publicURL.includes(".mp4")) ||
      (item.url && item.url.includes(".mp4"))
    ) {
      return (
        <Video
          videoTitle={mediaItem.alternativeText}
          videoSrcURL={
            item.url
              ? `${process.env.GATSBY_MEDIA_BASE_URL}${item.url}`
              : `${item.publicURL}`
          }
          autoPlay={videoSettings?.autoPlay}
          showControls={videoSettings?.showControls}
          muted={videoSettings?.muted}
          loop={videoSettings?.loop}
        />
      )
    } else if (mime.includes("image")) {
      return <Image image={item} imageAlt={mediaItem.alternativeText} />
    }
  }

  return getMedia(mediaItem)
}
