import "./contactbanner.scss";
import { graphql, useStaticQuery } from "gatsby"
import React from 'react'

interface Props {
}

export const ContactBanner: React.FC<Props> = () => {
  const {
    strapiContact: { bannerText, bannerEmail, bannerPhone },
  } = useStaticQuery(CONTACT_PAGE_QUERY)

  return (
    <div className="contact-banner">
      <span className="header-text">
        <div className="logo header-logo" />
        <p>{bannerText}</p>
      </span>
      <span className="contact-details">
        <a href={`mailto:${bannerEmail}`}>{bannerEmail}</a>
        <a href={`tel:${bannerPhone}`}>{bannerPhone}</a>
      </span>
    </div>
  );
}

export const CONTACT_PAGE_QUERY = graphql`
  query {
    strapiContact {
      bannerText
      bannerEmail
      bannerPhone
    }
  }
`