import "./layout.scss"
import "../../styles/main.scss"

import React, { PropsWithChildren, ReactNode } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "../header/header"
import { ContactBanner } from "../contactbanner/contactbanner"

// @ts-ignore
import KatpatatIcon from "../../../assets/svg/katpatat-logo.svg"

// @ts-ignore
import InstagramIcon from "../../../assets/svg/instagram-brands.svg"
// @ts-ignore
import TwitterIcon from "../../../assets/svg/twitter-brands.svg"

// @ts-ignore
import ItchIcon from "../../../assets/svg/itch-io-brands.svg"
// @ts-ignore
import CodepenIcon from "../../../assets/svg/codepen-brands.svg"
// @ts-ignore
import TumblrIcon from "../../../assets/svg/tumblr-brands.svg"
// @ts-ignore
import MediumIcon from "../../../assets/svg/medium-m-brands.svg"

// @ts-ignore
import LinksIcon from "../../../assets/svg/pizza-slice-solid.svg"

const Layout: React.FC<PropsWithChildren<ReactNode>> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="container">
      <ContactBanner />
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer>
        <span className="socials">
          <Link to="/contact">
            <KatpatatIcon className="social-icon" /> Contact us
          </Link>
          <a
            href="https://links.katpatat.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LinksIcon className="social-icon" /> Our Socials
          </a>
          {/* <a
            href="https://instagram.com/patatjes4life/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <InstagramIcon className="social-icon" /> Instagram
          </a>
          <a
            href="https://twitter.com/ogkatpatat/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <TwitterIcon className="social-icon" /> Twitter
          </a>
          <a
            href="https://patatjes4life.itch.io/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <ItchIcon className="social-icon" /> Itch.io
          </a>
          <a
            href="https://codepen.io/KATPATAT"
            rel="noopener noreferrer"
            target="_blank"
          >
            <CodepenIcon className="social-icon" /> Codepen
          </a>
          <a
            href="https://katpatat.tumblr.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <TumblrIcon className="social-icon" /> Tumblr
          </a>
          <a
            href="https://medium.com/@lisagameart"
            rel="noopener noreferrer"
            target="_blank"
          >
            <MediumIcon className="social-icon" /> Medium
          </a> */}
        </span>
        <span className="details">
          <p className="copyright">© {new Date().getFullYear()} Katpatat</p>
          <p>KvK: 80982565</p>
        </span>
      </footer>
    </div>
  )
}

export default Layout
