import "../styles/home.scss"
import React, {
  useState,
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
} from "react"
import { graphql, PageProps } from "gatsby"

import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import { ProjectCard } from "../components/project-card/project-card"

interface ProjectContextData {
  currentCategory: string
  setCurrentCategory: Dispatch<SetStateAction<string>>
}

export const ProjectContext = createContext<ProjectContextData>({
  currentCategory: "front-page",
  setCurrentCategory: () => {},
})

// @ts-ignore
interface Props extends PageProps {
  data: any
  location: {
    state: {
      category: string
    }
    search: string
  }
}

const IndexPage: React.FC<Props> = ({
  data: {
    allStrapiProject: { nodes },
  },
  location,
}) => {
  const [projects, setProjects] = useState([])
  const [currentCategory, setCurrentCategory] = useState(
    location.state?.category ? location.state?.category : "front-page"
  )

  useEffect(() => {
    const getCurrentProjects = () => {
      let pj = []

      if (nodes) {
        nodes.sort((a, b) => {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return (
            new Date(b.publicationDate).getTime() -
            new Date(a.publicationDate).getTime()
          )
        })

        if (currentCategory == "all") {
          pj = nodes.map(project => (
            <ProjectCard key={project.id} project={project} />
          ))
        } else if (currentCategory !== "front-page") {
          pj = nodes
            .filter(project =>
              project.categories.find(
                (x: { slug: string }) => x.slug === currentCategory
              )
            )
            .map(project => <ProjectCard key={project.id} project={project} />)
        } else {
          pj = nodes
            .filter(project =>
              project.categories.find(
                (x: { slug: string }) => x.slug === "front-page"
              )
            )
            .map(project => <ProjectCard key={project.id} project={project} />)
        }
      }

      setProjects(pj)
    }

    getCurrentProjects()
  }, [currentCategory])

  return (
    <ProjectContext.Provider value={{ currentCategory, setCurrentCategory }}>
      <Layout>
        <SEO title="Home" />
        <div id="projects">{projects}</div>
      </Layout>
    </ProjectContext.Provider>
  )
}

export default IndexPage

export const HOME_PAGE_QUERY = graphql`
  query {
    allStrapiProject(filter: { status: { eq: "published" } }) {
      nodes {
        id
        title
        slug
        publicationDate
        categories {
          slug
        }
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxHeight: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        status
      }
    }
  }
`
