import "./video.scss"
import React from "react"

interface Props {
  videoSrcURL: string
  videoTitle?: string
  videoType?: string
  autoPlay?: boolean
  showControls?: boolean
  muted?: boolean
  loop?: boolean
  onClickEvent?: any
}

export const Video: React.FC<Props> = ({
  videoSrcURL,
  videoTitle,
  videoType = "mp4",
  autoPlay = false,
  showControls = true,
  muted = true,
  loop = true,
  onClickEvent,
  ...props
}) => {
  return (
    <div className="gatsby-video-wrapper">
      <video
        key={videoSrcURL}
        controls={showControls}
        autoPlay={autoPlay}
        muted={muted}
        loop={loop}
      >
        <source
          title={videoTitle}
          src={videoSrcURL}
          type={`video/${videoType}`}
        />
      </video>
    </div>
  )
}
