import React from "react"
import { Link } from "gatsby"
import { Media } from "../media/media"

interface Props {
  project: {
    id: string
    title: string
    slug: string
    categories: {
      slug: string
    }
    featuredImage
  }
}

export const ProjectCard: React.FC<Props> = ({ project }) => {
  return (
    <div className="project-card">
      <Link to={`/project/${project.slug}`}>
        {project.featuredImage && (
          <Media mediaItem={project.featuredImage} mime="image" videoSettings={{ autoPlay: true, muted: true, showControls: false, loop: true }} />
        )}
        <h2>{project.title}</h2>
      </Link>
    </div>
  )
}
