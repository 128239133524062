import "./header.scss"

import React, { useContext, useEffect } from "react"
import { Menu } from "../menu/menu"
import { Link, useStaticQuery, graphql } from "gatsby"
import queryString from "query-string"

import { CategorySelection } from "../category-selection/category-selection"
import { ProjectContext } from "../../pages"

interface Props {
  siteTitle: string
}

const Header: React.FC<Props> = ({ siteTitle }) => {
  const {
    allStrapiCategory: { nodes: categories },
  } = useStaticQuery(CATEGORIES_QUERY)

  const { setCurrentCategory } = useContext(ProjectContext)

  useEffect(() => {
    const catValue = queryString.parse(location.search)

    if (categories.some(cat => cat.slug === catValue.category)) {
      setCurrentCategory(catValue.category)
    }
  }, [])

  return (
    <header>
      <span className="logo" />
      <Link
        id="site-title"
        to="/"
        onClick={() => {
          setCurrentCategory("front-page")
        }}
      >
        <h1>{siteTitle}</h1>
      </Link>
      <CategorySelection categories={categories} />
      <Menu />
    </header>
  )
}

export default Header

const CATEGORIES_QUERY = graphql`
  query {
    allStrapiCategory(
      filter: {
        projects: {
          elemMatch: { id: { ne: "null" }, status: { eq: "published" } }
        }
      }
    ) {
      nodes {
        name
        id
        slug
      }
    }
  }
`
