import "./menu.scss"
import React, { useState } from "react"

import { Link } from "gatsby"

interface Props {}

export const Menu: React.FC<Props> = () => {
  const [expandedState, setExpandedState] = useState(false)

  return (
    <>
      <button
        id="menu"
        onClick={() => {
          setExpandedState(!expandedState)
        }}
        tabIndex={-1}
      >
        <h2>Menu</h2>
      </button>
      <ul className={expandedState ? "menu-items expand" : "menu-items"}>
        <li>
          <Link
            to="/about"
            onFocus={() => {
              if (expandedState === false) setExpandedState(true)
            }}
            onBlur={() => {
              if (expandedState === true) setExpandedState(false)
            }}
          >
            About
          </Link>
        </li>
        <li>
          <Link
            to="/contact"
            onFocus={() => {
              if (expandedState === false) setExpandedState(true)
            }}
            onBlur={() => {
              if (expandedState === true) setExpandedState(false)
            }}
          >
            Contact
          </Link>
        </li>
      </ul>
    </>
  )
}
